const config = {};
const production = true;
const baseDev = 'http://localhost:3000';
const baseProduction = 'https://api.br5beneficios.com.br';

config.base = production ? baseProduction : baseDev;
config.production = production;
config.urlApp = 'https://licencas.br5turbo.com.br';
config.urlSite = 'https://br5turbo.com.br';

export default config;
