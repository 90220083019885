import React, { useContext } from "react";
import PropTypes from "prop-types";
import NavbarWrapper from "../../common/components/Navbar";
import Drawer from "../../common/components/Drawer";
import Logo from "../../common/components/UIElements/Logo";
import Box from "../../common/components/Box";
import HamburgMenu from "../../common/components/HamburgMenu";
import Container from "../../common/components/UI/Container";
import { DrawerContext } from "../../common/contexts/DrawerContext";

import { MENU_ITEMS } from "../../common/data";
import ScrollSpyMenu from "../../common/components/ScrollSpyMenu";

const LogoImage = {
  src: "/assets/image/br5-licencas.png",
};
const LogoImageTopAlt = {
  src: "/assets/image/logo-topseguros-branca.png",
};
const LogoImageExpertiseAlt = {
  src: "/assets/image/logo-expertise-branca.png",
};
const LogoImageAlt = {
  src: "/assets/image/br5-licencas.png",
};

const Navbar = ({ navbarStyle, logoStyle, button, row, menuWrapper }) => {
  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: "TOGGLE",
    });
  };

  return (
    <NavbarWrapper {...navbarStyle} className="saas_navbar">
      <Container>
        <Box {...row}>
          <Box style={{ display: 'flex' }}>
            <Logo
              href="#"
              logoSrc={LogoImage}
              title="Portfolio"
              logoStyle={logoStyle}
              style={{ marginTop: 9 }}
              className="main-logo"
            />
            <Logo
              href="#"
              logoSrc={LogoImageAlt}
              title="Portfolio"
              logoStyle={logoStyle}
              style={{ marginTop: 9 }}
              className="logo-alt"
            />
            <div style={{ paddingRight: 20 }} />
            {
              window?.location?.pathname.includes('topseguros') &&
              (
                <>
                  <Logo
                    href="#"
                    logoSrc={LogoImageTopAlt}
                    title="Portfolio"
                    logoStyle={logoStyle}
                    className="main-logo"
                  />
                  <Logo
                    href="#"
                    logoSrc={LogoImageTopAlt}
                    title="Portfolio"
                    logoStyle={logoStyle}
                    className="logo-alt"
                  />
                </>
              )
            }
            {
              window?.location?.pathname.includes('expertise') &&
              (
                <>
                  <Logo
                    href="#"
                    logoSrc={LogoImageExpertiseAlt}
                    title="Portfolio"
                    logoStyle={logoStyle}
                    className="main-logo"
                  />
                  <Logo
                    href="#"
                    logoSrc={LogoImageExpertiseAlt}
                    title="Portfolio"
                    logoStyle={logoStyle}
                    className="logo-alt"
                  />
                </>
              )
            }
          </Box>
          <Box {...menuWrapper}>
            <ScrollSpyMenu
              className="main_menu"
              menuItems={MENU_ITEMS}
              offset={-70}
            />
            {/* <Link href="#">
              <a className="navbar_button">
                <Button {...button} title="Nosso site" />
              </a>
            </Link> */}
            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu barColor="#fff" />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <ScrollSpyMenu
                className="mobile_menu"
                menuItems={MENU_ITEMS}
                drawerClose={true}
                offset={-100}
              />
              {/* <Link href="#">
                <a className="navbar_drawer_button">
                  <Button {...button} title="Nosso site" />
                </a>
              </Link> */}
            </Drawer>
          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: "70px",
    display: "block",
  },
  row: {
    flexBox: true,
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  logoStyle: {
    maxWidth: ["120px", "130px"],
  },
  button: {
    type: "button",
    fontSize: "13px",
    fontWeight: "700",
    borderRadius: "4px",
    pl: "15px",
    pr: "15px",
    colors: "secondaryWithBg",
    minHeight: "auto",
    height: "40px",
  },
  menuWrapper: {
    flexBox: true,
    alignItems: "center",
  },
};

export default Navbar;
