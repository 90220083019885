import styled from "styled-components";

export const Span = styled.span`
  font-size: 16px;
  margin-left: 5px;
  margin-top: -30px;
  // display: none;
  display: inline-block;
  transition: 0.95s all ease;
`

export const IconWrapper = styled.div`
  position: fixed;
  // width: 60px;
  // height: 60px;
  bottom: 100px;
  right: 20px;
  background-color: #25d366;
  color: #fff;
  border-radius: 10px;
  text-align: center;
  // font-size: 20px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  padding: 10px;
  cursor: pointer;
  transform: scale(1); /* you need a scale here to allow it to transition in both directions */
  transition: 0.95s all ease;
  &:hover {
    transform: scale(1.1);
    // border: 2px solid #ddd;
    ${Span} {
      display: inline !important;
      transition: 0.95s all ease;
    }
  }
`;

