import React, { Fragment, useEffect } from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { theme } from "../common/theme/default";
import { ContentWrapper } from "../containers/style";
import { DrawerProvider } from "../common/contexts/DrawerContext";
import Navbar from "../containers/Navbar";
import Banner from "../containers/BannerSectionQuote";
import Transactions from "../containers/TransactionQuote";
import Footer from "../containers/Footer";

const App = ({ match }) => {

  const {
    params: { intication, company, opened },
  } = match;

  useEffect(() => {
    document.title = 'Central de Serviços - Cotação de Seguros - Top Seguros'
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <Banner />
          <Transactions company={company} intication={intication} opened={opened} />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export default App;
