/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import React, { Fragment } from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { theme } from "../common/theme/default";
import { ContentWrapper } from "../containers/style";
import { DrawerProvider } from "../common/contexts/DrawerContext";
import Navbar from "../containers/Navbar";
import Banner from "../containers/BannerSection";
import Transactions from "../containers/Transaction";
import Footer from "../containers/Footer";
import { handleLoading, handleModalForm } from "../redux/general/generalSlice";
import { getSeller } from "../services/api/seller";
import { setSeller } from "../redux/seller/sellerSlice";
import ContinueInformation from "../containers/ContinueInformationWrapper";

const App = ({ match }) => {
  const dispatch = useDispatch();

  const {
    params: { intication, company },
  } = match;

  const init = async () => {
    try {
      dispatch(handleLoading(true));
      const { data } = await getSeller(intication);
      dispatch(setSeller(data));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(handleLoading(false));
    }
  };

  useEffect(() => {
    if (intication) init();
  }, [intication]);

  useEffect(() => {
    if (company) dispatch(handleModalForm(true))
  }, [company]);

  return (
    <ThemeProvider theme={theme}>
      <ContinueInformation intication={intication} />
      <Fragment>
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <Banner />
          <Transactions />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export default App;
