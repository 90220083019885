/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from  'react-toastify'
import "react-toastify/dist/ReactToastify.css";
import { useDispatch }  from 'react-redux'
import PropTypes from "prop-types";
import Box from "../../common/components/Box";
import Fade from "react-reveal/Fade";
import Text from "../../common/components/Text";
import Heading from "../../common/components/Heading";
import Button from "../../common/components/Button";
import FeatureBlock from "../../common/components/FeatureBlock";
import Container from "../../common/components/UI/Container";
import { TransactionsWrapper, FeatureSection } from "./transaction.style";
import Input from "../../common/components/Input";
import Select from "../../common/components/Select";
import config from '../../config';
import { FileUploader } from "react-drag-drop-files";
import api from "../../services/axiosInstance";
import WhatsappButton from "../../common/components/WhatsappButton";
import axios from 'axios';
import { handleLoading } from "../../redux/general/generalSlice";

const fileTypes = ["JPG", "JPEG", "PNG", "PDF", "DOC", "DOCX"];

const TransactionsHistory = ({
  row,
  col,
  title,
  titleSuccess,
  description,
  descriptionSuccess,
  btnStyle,
  sectionSubTitle,
  cardArea,
  featureTitleStyle,
  featureDescriptionStyle,
  button,
  company,
  intication,
  opened
}) => {
  const insuranceTypes = [
    {
      label: "Seguro de Vida",
      value: 0,
    },
    {
      label: "Seguro de Saúde",
      value: 1,
    },
    {
      label: "Seguro Carro ou Moto",
      value: 2,
    },
    {
      label: "Seguro da Empresa",
      value: 3,
    },
    {
      label: "Outros",
      value: 4,
    },
  ];

  const dispatch = useDispatch();
  const [form, setForm] = useState({});
  const [success, setSuccess] = useState(false);

  const getBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setForm({
        ...form,
        file: reader.result,
      });
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(handleLoading(true));
    try {
      const { insuranceTypes, ..._form } = form
      if (!form.name || !form.phone  || !form.email) {
        throw new Error('Dados incompletos')
      }
      const data = {
        ..._form,
        product: insuranceTypes?.label,
        company,
        intication
      };

      await api.post(`${config.base}/services/checkout/iniciar-cotacao`, data)
      // axios.post("services/checkout/iniciar-cotacao", data);

      toast.dismiss();
      toast.success('🦄 Sua Mensagem foi enviada com sucesso!');

      setSuccess(true);

      console.log('enviou')
    } catch (error) {

      toast.error('Erro ao enviar mensagem. Todos os dados precisam ser preenchidos',{
        position: "top-right",
      })
      console.log(' asasdas ', error);
    } finally {
       dispatch(handleLoading(false));
    }
  };

  const handleChange = (props, value) => {
    setForm({
      ...form,
      [props]: value,
    });
  };

  const ref = React.useRef(null);

  useEffect(() => {
    if (!opened) return;
    (function (w, d, t, o = {}) {
      var f = d.getElementsByTagName("script")[0],
        b = d.createElement("script");
      w["robocoteParams"] = o;
      b.src = "https://app.robocote.com/bot/" + t;
      f.parentNode.insertBefore(b, f);
    })(window, document, "ACDB2449858C40DF8493454AF573012D", { expanded: !!opened, open: !!opened, visible: true });
  }, [opened])

  // useEffect(() => {
  //   if (opened && typeof ref.current.click === 'function') {
  //     window.robocoteParams = { expanded: true, open: true, visible: true }
  //     console.log('ref.current', ref.current)
  //     console.log('opened', opened)
  //     ref.current.click();
  //   }
  // }, [ref.current])

  return (
    <TransactionsWrapper id="transactions">
      { !opened && <WhatsappButton /> }
      <Container>
        <ToastContainer
          position="top-right"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          />
        <a
          ref={ref}
          className="navbar_drawer_button"
          href="javascript:robocoteOpenChat()"
        ></a>
        <Box className="row" {...row}>
          <Box className="colleft" {...col} style={{ flexDirection: "column" }}>
            <Text {...sectionSubTitle} />
            <FeatureBlock
              title={<Heading {...title} />}
              description={<Text {...description} />}
            />
          </Box>
          {
            success ?
            (
              <Box className="colleft" {...col} style={{ flexDirection: "column" }}>
                {/* <Text {...sectionSubTitle} /> */}
                <FeatureBlock
                  title={<Heading {...titleSuccess} />}
                  description={<Text {...descriptionSuccess} />}
                />
              </Box>
            )
            :
            (
              <Box className="colright" {...col} {...cardArea}>
            <FeatureSection>
              <Fade up>
                <div className="featureWrapper">
                  <Heading
                    as="h3"
                    content="Cotação de Seguros"
                    {...featureTitleStyle}
                  />
                  <Text
                    content="Preencha o formulário abaixo com seus dados, que o resto é com a gente."
                    {...featureDescriptionStyle}
                  />
                  <Box mt="10">
                    <Input
                      inputType="text"
                      label="Nome Completo*"
                      name="name"
                      onChange={(e) => handleChange("name", e)}
                      required
                      autoComplete="new-password"
                    />
                  </Box>
                  <div className="two-inputs">
                    <Box width="100%" mt="4" mr="2">
                      <Input
                        inputType="phone"
                        label="Número de Telefone*"
                        name="phone"
                        onChange={(e) => handleChange("phone", e)}
                        required
                        autoComplete="new-password"
                      />
                    </Box>
                    <Box width="100%" mt="4" ml="2">
                      <Input
                        inputType="text"
                        label="E-mail *"
                        name="e-mail"
                        onChange={(e) => handleChange("email", e)}
                        autoComplete="new-password"
                      />
                    </Box>
                  </div>
                  <Select
                    labelText="Qual seguro deseja?"
                    className="insurance_type"
                    aria-label="select options"
                    placeholder="Selecione um..."
                    options={insuranceTypes}
                    value={form?.insuranceTypes}
                    onChange={(e) => handleChange("insuranceTypes", e)}
                  />
                  <h4>Deseja anexar sua Apólice?</h4>
                  <span className="text-2">Nossa equipe de especialistas buscará os melhores preços para seu seguro</span>
                  <div style={{ marginTop: 30 }}>
                    <FileUploader
                      label="Clique ou arraste o arquivo para fazer upload"
                      handleChange={(e) => getBase64(e)}
                      name="file"
                      types={fileTypes}
                    />
                  </div>

                  <Button
                    {...button}
                    title="ENVIAR FORMULÁRIO"
                    onClick={handleSubmit}
                  />
                </div>
              </Fade>
            </FeatureSection>
          </Box>
            )
          }
        </Box>
      </Container>
    </TransactionsWrapper>
  );
};

// Transactions style props
TransactionsHistory.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  featureTitleStyle: PropTypes.object,
  button: PropTypes.object,
  featureDescriptionStyle: PropTypes.object,
};

// Transactions default style
TransactionsHistory.defaultProps = {
  // Transactions section row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
  },
  // Transactions section col default style
  col: {
    pr: "15px",
    pl: "15px",
    width: [1, 1 / 2, 1 / 2, 1 / 2, 1 / 2],
    flexBox: true,
    alignSelf: "center",
  },

  // Transactions section title default style
  title: {
    content: "Cotação de seguros com as maiores seguradoras do País",
    fontSize: ["24px", "26px", "30px", "36px", "40px"],
    lineHeight: ["30px", "32px", "40px", "50px", "55px"],
    fontWeight: "700",
    color: "#32325d",
    letterSpacing: "-0.010em",
    mb: "20px",
    maxWidth: ["100%", "100%", "100%", "100%", "415px"],
  },
  titleSuccess: {
    content: "Cotação enviada!",
    fontSize: ["24px", "26px", "30px", "36px", "40px"],
    lineHeight: ["30px", "32px", "40px", "50px", "55px"],
    fontWeight: "700",
    color: "#32325d",
    letterSpacing: "-0.010em",
    mb: "20px",
    maxWidth: ["100%", "100%", "100%", "100%", "415px"],
  },
    descriptionSuccess: {
    content:
      "Fique tranquilo! Sua cotação será analisada e enviada em breve!",
    fontSize: "16px",
    fontWeight: "400",
    color: "#525f7f",
    lineHeight: "28px",
    mb: ["30px", "30px", "40px", "40px", "55px"],
    textAlign: ["left", "left"],
    maxWidth: ["100%", "100%", "100%", "100%", "430px"],
  },
  // Transactions section description default style
  description: {
    content:
      "Fique tranquilo! Sua cotação chegará até nós e entraremos em contato com os melhores preços.",
    fontSize: "16px",
    fontWeight: "400",
    color: "#525f7f",
    lineHeight: "28px",
    mb: ["30px", "30px", "40px", "40px", "55px"],
    textAlign: ["left", "left"],
    maxWidth: ["100%", "100%", "100%", "100%", "430px"],
  },
  sectionSubTitle: {
    content: "Cote seu seguro agora!",
    as: "span",
    textAlign: "left",
    fontSize: ["16px", "16px", "18px", "20px", "20px"],
    fontFamily: "Poppins",
    fontWeight: "600",
    lineHeight: "27px",
    color: "#525f7f",
  },
  // Button default style
  btnStyle: {
    minWidth: "156px",
    fontSize: "14px",
    fontWeight: "500",
  },
  featureTitleStyle: {
    fontSize: ["18px", "18px", "20px", "20px", "20px"],
    lineHeight: ["25px", "27px", "27px", "27px", "27px"],
    fontWeight: "500",
    color: "#32325d",
    letterSpacing: "-0.010em",
    mb: "10px",
  },
  button: {
    type: "button",
    fontSize: "21px",
    borderRadius: "4px",
    pl: "10px",
    pr: "10px",
    colors: "primaryWithBg",
    minHeight: "auto",
    mt: "25px",
  },
  // Transactions section description default style
  featureDescriptionStyle: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#525f7f",
    lineHeight: "27px",
  },
};

export default TransactionsHistory;
