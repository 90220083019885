import config from '../config';
import axios from "axios";

const baseURL = "https://warm-phones-joke-201-71-155-225.loca.lt/";

const api = axios.create({
  baseURL: config.base,
  responseType: "json",
  headers: {
    Accept: "application/json",
    ContentType: "text/plain",
  },
});

api.interceptors.request.use(async (config) => {
  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    return Promise.reject(error);
  }
);

export default api;
