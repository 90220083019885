import React from "react";
import { Switch, Route } from "react-router-dom";

import Main from "../pages/Main";
import CotarSeguro from "../pages/CotarSeguro";
import NotFoundPage from "../pages/404";

const routes = () => (
  <Switch>
    <Route path="/:intication/:company/cotar-seguros" exact component={CotarSeguro} />
    <Route path="/:intication/:company/cotar-seguros/:opened" exact component={CotarSeguro} />
    <Route path="/:intication/:company?" exact component={Main} />
    <Route path="/" exact component={Main} />
    <Route path="*" exact component={NotFoundPage} />
  </Switch>
);

export default routes;
