import React from "react";
import { Router } from "react-router-dom";
import ResetCSS from "./common/assets/css/style";
import LoadingScreen from "./common/components/LoadingScreen";
import { GlobalStyle } from "./containers/style";
// import WhatsappButton from "./common/components/WhatsappButton";

import history from "./helpers/history";

import Routes from "./routes";

import "@redq/reuse-modal/es/index.css";

import "swiper/css/bundle";
import "rc-collapse/assets/index.css";

function App() {
  return (
    <Router history={history}>
      <LoadingScreen />
      {/* <WhatsappButton /> */}
      <Routes />
      <ResetCSS />
      <GlobalStyle />
    </Router>
  );
}

export default App;
