import React from "react";

import { IconWrapper, Icon, Span } from "./whatsappbutton.style";

const WhatsappButton = () => {
  const openLink = (event, text) => {
    text = text || 'Olá! Quero cotar a renovação do meu seguro!';
    // const url = window.location.href;
    const phone = '5565999025474'
    window.open(
      `https://api.whatsapp.com/send?phone=${phone}&text=${text}`,
      "_blank"
    );
  };

  return (
    <IconWrapper onClick={(e, text) => openLink(e, text)} target="_blank" rel="noreferrer">
      <i className="fa fa-whatsapp" style={{ marginTop: 0, fontSize: 28 }} />
      <Span>Faça sua cotação pelo Whatsapp</Span>
    </IconWrapper>
  );
};

export default WhatsappButton;
